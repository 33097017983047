import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import BusinessModelCanvas from "../components/canvas/BusinessModelCanvas";
import ModelCanvas from "../components/canvas/ModelCanvas";
import { CanvasLinkParent, ItemTypeIcon } from "../components/control/ItemTypeIcon";
import ToolbarAction from "../components/control/ToolbarAction";
import { useModelContext } from "../context/ModelContext";
import { useViewContext } from "../context/ViewContext";

const logger = new Logger("view.CanvasView");

function CanvasView(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const { pathname } = useLocation();
  const { key } = (useParams() as any);
  const rootKey = key || "";
  const item = model.getItem(rootKey);

  // This will sync the sidebar
  React.useEffect(() => {
    if (model.has(rootKey)) {
      viewState.openTo(model, rootKey);
      viewState.onEvent({name:"CanvasView", key:rootKey});
    }
  }, [pathname]);

  // Business model a special canvas
  let activeTab = 1;
  if (item && item.key.startsWith("BM-")) {
    activeTab = 2;
  }

  logger.debug("Rendering: rootKey=%s, activeTab=%d:", rootKey, activeTab, props);

  return (
    <Card className="jemstone-view document shadow">
      <CardHeader className="">
        <div className="d-flex text-nowrap align-middle">
          { item &&
            <>
              <div className="h1 flex-fill">
                <ItemTypeIcon item={item} />
                <div className="d-inline ml-1 mr-1">{item.name} Canvas</div>
                <CanvasLinkParent item={item} />
              </div>
              <div className="pt-1 mr-3">{item.key}</div>
              <ToolbarAction rootKey={rootKey} docView={true} showExpandAll={activeTab === 2} showRefresh={true} />
            </>
          }
        </div>
      </CardHeader>
      <CardBody className="">
        { activeTab === 1 &&
          <ModelCanvas rootKey={rootKey} showKey={false} showDescription={false} version={2} />
        }
        { activeTab === 2 &&
          <BusinessModelCanvas rootKey={rootKey} showKey={false} showDescription={true} />
        }
      </CardBody>
    </Card>
  );
}

export default CanvasView;
